<style lang="less">
.QuillEditor_Module {
  margin-top: 40px;

  .editor {
    .ql-editor,
    .ql-editor.ql-blank {
      min-height: 200px;
      img {
        width: 100%;
      }
    }
    .ql-tooltip,
    .ql-editing {
      left: 0 !important;
    }
  }
}
</style>
<template>
  <div class="QuillEditor_Module">
    <quill-editor
      class="editor"
      v-model="content"
      :options="editorOption"
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @change="onEditorChange($event)"
    />
    <div :class="resultMethods">
      <el-upload action="#" :show-file-list="false" :http-request="uploadFile">
      </el-upload>
    </div>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
// 工具栏配置
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
  ["blockquote", "code-block"], // 引用  代码块
  [{ header: 1 }, { header: 2 }], // 1、2 级标题
  [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
  [{ script: "sub" }, { script: "super" }], // 上标/下标
  [{ indent: "-1" }, { indent: "+1" }], // 缩进
  // [{'direction': 'rtl'}],                         // 文本方向
  [{ size: ["small", false, "large", "huge"] }], // 字体大小
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
  [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
  [{ font: [] }], // 字体种类
  [{ align: [] }], // 对齐方式
  ["clean"], // 清除文本格式
  ["link", "image", "video"], // 链接、图片、视频
];
export default {
  name: "quill-editor-module",
  components: { quillEditor },
  props: {
    paramsText: { default: "", type: String },
    resultMethods: { default: "", type: String },
  },
  data() {
    return {
      uploadData: {}, // 图片文件
      serverUrl: "/dev-api/wxmaterial/newsImgUpload", // 这里写你要上传的图片服务器地址
      header: {
        // 有的图片服务器要求请求头需要有token，有的可以加上
        Authorization: "Bearer ",
      },
      quillUpdateImg: false, // 根据图片上传状态来确定是否显示loading动画，刚开始是false,不显示
      content: "", // 内容
      editorOption: {
        // 配置项
        theme: "snow", // or 'bubble'
        placeholder: "请输入课程内容",
        modules: {
          toolbar: {
            container: toolbarOptions,
            // container: "#toolbar",
            handlers: {
              image: (value) => {
                if (value) {
                  // 触发input框选择图片文件
                  let str = "." + this.resultMethods + " .el-upload input";
                  document.querySelector(str).click();
                } else {
                  this.quill.format("image", false);
                }
              },
              link: function (value) {
                if (value) {
                  var href = prompt("注意！只支持公众号图文链接");
                  this.quill.format("link", href);
                } else {
                  this.quill.format("link", false);
                }
              },
            },
          },
        },
      },
    };
  },
  watch: {
    paramsText(n) {
      if (n) {
        this.content = n;
      } else {
        this.content = "";
      }
    },
  },
  mounted() {
    this.content = this.paramsText || "";
  },
  methods: {
    uploadFile(file) {
      let fileDate = file.file;
      let forms = new FormData();
      forms.append("file", fileDate);
      this.openLoadingView();
      this.$axios_supermall({
        method: "post",
        url: "/hnjxjy-core/teacherCourse/updateRichText",
        data: forms,
      })
        .then((res) => {
          if (res.data.code === 2000) {
            // editor.html.append("<img src='"+res.data.data+"'/>")
            this.$emit(
              this.resultMethods,
              "<img src='" + res.data.data + "'/>"
            );
          }
        })
        .catch((err) => {
        });
    },
    onEditorBlur(editor) {
      //失去焦点事件
    },
    onEditorFocus(editor) {
      //获得焦点事件
    },
    onEditorChange(editor) {
      //内容改变事件
      this.$emit("quillEditorText", editor.text);
      this.$emit("quillEditorHtml", editor.html);
    },
  },
};
</script>
